import React, { useEffect } from 'react';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { ApolloLink, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { actions as authActions, getModuleState } from 'services/auth';
import { useDispatch, useSelector } from 'react-redux';
import config from 'config';
import { getUserPool } from 'userPool';

const useGetAuth = () => {
  const authState = useSelector(getModuleState);
  const dispatch = useDispatch();
  const { userPoolId, clientId, username, session, workspace } = authState;

  const [currentUser, setCurrentUser] = React.useState(
    username && userPoolId
      ? new CognitoUser({
          Username: username,
          Pool: getUserPool({
            UserPoolId: userPoolId,
            ClientId: clientId,
          }),
        })
      : null
  );

  const getCurrentUser = () => {
    const currentUser = getUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    }).getCurrentUser();

    currentUser.setSignInUserSession(
      new CognitoUserSession({
        AccessToken: new CognitoAccessToken({
          AccessToken: session.accessToken.jwtToken,
        }),
        IdToken: new CognitoIdToken({ IdToken: session.idToken.jwtToken }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: session.refreshToken.token,
        }),
      })
    );
    return currentUser;
  };

  const getNewAccessToken = async () => {
    console.log('GET NEW ACCESS TOKEN');
    return new Promise((resolve, reject) => {
      if (session.isPSC) {
        console.log(JSON.stringify({ session }));
        fetch(
          config.API_HOST +
            '/pro-sante-connect/refresh-token?refresh_token=' +
            session.refreshToken
        ).then(
          (res) =>
            res.json().then((res) => {
              console.log('RES REEFRESH TOKEN PSC:', res);
              dispatch(authActions.refreshSession({ ...res, isPSC: true }));
              resolve(res.accessToken);
            }),
          (error) => {
            console.log('Error Refresh token PSC:', error);
            reject(error);
          }
        );
      } else {
        const refreshToken = new CognitoRefreshToken({
          RefreshToken: `${session?.refreshToken?.token}`,
        });

        currentUser.refreshSession(refreshToken, async (err, newSession) => {
          if (!newSession && err) {
            reject(err);
          } else {
            dispatch(authActions.refreshSession(newSession));
            resolve(newSession);
          }
        });
      }
    });
  };

  const handleAuth = (
    values,
    poolData,
    workspace,
    setNewPassword,
    needMfaCode
  ) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: values.username,
        Pool: getUserPool({
          UserPoolId: poolData.userPoolId,
          ClientId: poolData.clientId,
        }),
      });

      const authDetails = new AuthenticationDetails({
        Username: values.username,
        Password: values.password,
      });

      try {
        user.authenticateUser(authDetails, {
          onSuccess: async (data) => {
            console.log('Login successful!', data);

            const sendbirdData = await fetch(
              config.API_HOST + '/sendbird/dashboard-user/auth',
              {
                headers: {
                  Authorization: `Bearer ${data
                    .getAccessToken()
                    .getJwtToken()}`,
                  workspace,
                },
              }
            );

            if (sendbirdData) {
              sendbirdData
                .json()
                .then((response) =>
                  dispatch(authActions.setSendbirdData({ ...response }))
                );
            }

            dispatch(
              authActions.login({
                username: values.username,
                session: data,
                sendbirdSessionTokens: sendbirdData.session_tokens,
                sendbirdAccessToken: sendbirdData.accessToken,
              })
            );
            setCurrentUser(user);

            resolve(data);
          },

          onFailure: (err) => {
            reject(err);
          },

          newPasswordRequired: async (userAttributes) => {
            // console.log('New password required!', userAttributes);
            delete userAttributes.email_verified;
            await setNewPassword.open({ user, values });
            delete userAttributes.email;
            // What is the purpose of userAttributes?
          },

          mfaRequired: () => {
            needMfaCode.open({
              user,
              values,
            });
          },
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const errorLink = onError(({ networkError, operation, forward }) => {
    if (networkError?.statusCode === 401) {
      return new Observable((observer) => {
        const promise = getNewAccessToken();

        promise
          .then((accessToken) => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                authorization: `Bearer ${accessToken}`,
              },
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            console.log('Failed to refresh token! Error:', error);
            // No refresh or client token available, we force user to login
            if (session.isPSC) {
              fetch(
                config.PRO_SANTE_CONNECT_AUTH_HOST +
                  'realms/esante-wallet/protocol/openid-connect/logout?id_token_hint=' +
                  session.idToken
              ).then(
                () => {
                  dispatch(authActions.logout());
                  observer.error(error);
                },
                (err) => {
                  console.log(
                    'Error: Could not logout on ProSante Connect',
                    err
                  );
                  dispatch(authActions.logout());
                  observer.error(error);
                }
              );
            } else {
              dispatch(authActions.logout());
              observer.error(error);
            }
          });
      });
    }
  });

  const withAccessToken = () => {
    return new ApolloLink((operation, forward) => {
      if (!session) {
        return forward(operation);
      }

      operation.setContext({
        headers: {
          'Content-Type': `application/json`,
          Authorization:
            'Bearer ' + (session.accessToken.jwtToken || session.accessToken),
          Workspace: workspace,
        },
      });
      return forward(operation);
    });
  };

  return { withAccessToken, handleAuth, errorLink, getCurrentUser };
};

export default useGetAuth;
