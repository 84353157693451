import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';

export const getModuleState = (state) => state.call;

export const initialState = {
  user: undefined,
  calls: [],
  rooms: [],
  audioInputDeviceInfo: { current: {}, available: [] },
  audioOutputDeviceInfo: { current: {}, available: [] },
  videoInputDeviceInfo: { current: {}, available: [] },
  settingsState: { isFullscreen: true },
};

export const selectors = {
  getCallState(state) {
    return getModuleState(state);
  },
  getCalls(state) {
    const { calls } = getModuleState(state);
    return calls;
  },
  getCurrentCall(state) {
    const { calls } = getModuleState(state);
    return calls.find((call) => !call.isEnded);
  },
  getIsBusy(state) {
    const { calls } = getModuleState(state);
    return calls.some((call) => !call.isEnded);
  },
  getCallSettings(state) {
    const { settingsState } = getModuleState(state);
    return settingsState;
  },
};

const slice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    auth(state, { payload }) {
      state.user = payload;
    },
    deauth(state) {
      state = initialState;
    },
    addCall(state, { payload }) {
      state.calls.push(payload);
    },
    ringing(state, { payload }) {
      const isBusy = state.calls.some((call) => !call.isEnded);
      const call = payload;

      if (isBusy) {
        call.end();
      } else {
        state.calls.push(call);
      }
    },
    updateCall(state, { payload }) {
      state.calls.forEach((item, idx) => {
        if (item.callId === payload.callId) {
          state.calls[idx] = { ...item, ...payload };
        }
      });
    },
    clearCalls(state) {
      state.calls = initialState.calls;
    },
    updateAudioInputDeviceInfo(state, { payload }) {
      state.audioInputDeviceInfo = {
        ...state.audioInputDeviceInfo,
        ...payload,
      };
    },
    updateAudioOutputDeviceInfo(state, { payload }) {
      state.audioOutputDeviceInfo = {
        ...state.audioOutputDeviceInfo,
        ...payload,
      };
    },
    updateVideoInputDeviceInfo(state, { payload }) {
      state.videoInputDeviceInfo = {
        ...state.videoInputDeviceInfo,
        ...payload,
      };
    },
    updateCallSettings(state, { payload }) {
      state.settingsState = { ...state.settingsState, ...payload };
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
