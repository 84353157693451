import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import getClient from 'apolloClient';
import { getModuleState } from 'services/auth';
import { ApolloProvider, useLazyQuery } from '@apollo/client';
import { selectors, actions as userActions } from '../services/user';
import { GET_PROFILE_INFO } from 'pages/ProfileSettingsPage/gqlSchemes/getProfileInfo';
import useGetAuth from 'useGetAuth';
import useGetAlertsPage from 'pages/AlertsPage/_service/hooks/useGetAlertsPage';

const TeamMemberRouting = loadable(() => import('./TeamMemberRouting'));
const AdminRouting = loadable(() => import('./AdminRouting'));
const LoginRouting = loadable(() => import('./LoginRouting'));
const LoadingPage = loadable(() => import('components/LoadingPage'));

export const ConfirmDeleteDialogContext = React.createContext({});

const RouterComponent = ({ handleAuth }) => {
  const { i18n } = useTranslation();

  const authState = useSelector(getModuleState);
  const user = useSelector(selectors.getUser);
  const [fetchProfile, { data, loading, error }] = useLazyQuery(
    GET_PROFILE_INFO
  );
  const dispatch = useDispatch();

  const { getLazyQueryAlerts } = useGetAlertsPage(0, 1, {}, true);

  useEffect(() => {
    const currentMe = { ...data?.user?.dashboard?.me };
    if (currentMe && currentMe?.newRoles?.length) {
      dispatch(userActions.setUserInfo({ user: currentMe }));
    }
    if (currentMe && currentMe?.selectedLanguage) {
      i18n.changeLanguage(currentMe?.selectedLanguage);
    }
    getLazyQueryAlerts({
      variables: {
        page: 0,
        perPage: 1,
        filter: {
          assignedToMe: true,
          title: '',
          status: 'triggered',
        },
      },
      onCompleted: (res) => {
        dispatch(
          userActions.setTriggeredAlerts(
            res.alert.alertsPage.pageInfo.totalItems
          )
        );
      },
    });
  }, [data]);

  const Routing = useMemo(() => {
    const role = data?.user?.dashboard?.me?.newRoles.length
      ? data?.user?.dashboard?.me?.newRoles[0].type
      : 'unknown';

    if (!authState?.isAuthenticated) {
      if (user) {
        i18n.changeLanguage(user?.selectedLanguage);
      }
      return <LoginRouting handleAuth={handleAuth} />;
    } else {
      if (!loading && !error) {
        fetchProfile();
      }

      if (!user.id || loading) {
        return <LoadingPage />;
      } else {
        switch (role) {
          case 'unknown':
            return <LoginRouting handleAuth={handleAuth} />;
          case 'MEDICAL':
          case 'EXTERNAL':
            return <TeamMemberRouting />;
          case 'ADMINISTRATIVE':
            return <AdminRouting />;
          default:
            return <LoginRouting handleAuth={handleAuth} />;
        }
      }
    }
  }, [data, loading, user, authState, error]);

  return Routing;
};

export default function Routing() {
  const authData = useSelector((state) => state.auth);

  const { handleAuth, withAccessToken, errorLink } = useGetAuth(authData);
  const authLink = withAccessToken(authData);

  return (
    <ApolloProvider client={getClient(authLink, errorLink)}>
      <Router>
        <RouterComponent handleAuth={handleAuth} getClient={getClient} />
        <CssBaseline />
      </Router>
    </ApolloProvider>
  );
}
