import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuilderParts, CarePlanStatus, OrderByDate } from './constants';
import {
  CarePlan,
  CarePlansContainer,
  CarePlansState,
  Variable,
} from './models';
import { GetOne_ResponseData } from './_service/gqlSchemes/getOne';
import { Create_ResponseData } from './_service/gqlSchemes/create';
import { GqlCarePlanType } from '../../generated/graphql';
import { RootState } from '../../store/reducer';

export function getContainer(state: CarePlansState, type: GqlCarePlanType) {
  if (type === GqlCarePlanType.Template) {
    return state.list.template;
  }
  return state.list.workspace;
}

const getSliceState = (state: RootState) => state.carePlans;

export const selectors = {
  getCurrentCarePlan: (state: RootState) =>
    getSliceState(state).builder.currentCarePlan,
  getCarePlansWorkspace: (state: RootState) =>
    getSliceState(state).list.workspace.items,
  getCarePlanVariables: (state: RootState) =>
    getSliceState(state).builder.carePlanVariables,
  getImportantMetrics: (state: RootState) =>
    getSliceState(state).builder.currentCarePlan.importantMetricDefinitionIds,
};

const containerInitialState = {
  items: [] as CarePlan[],
  pageInfo: {
    page: 0,
    pageSize: 20,
  },
  ui: {
    called: false,
    loading: false,
  },
};

const emptyCarePlan = {
  id: '',
  title: '',
  benefits: [''],
  locations: [],
  members: [],
  actors: [],
  authorId: '',
  author: {
    id: null,
    name: null,
  },
  tags: [],
  duration: {
    months: null,
    weeks: null,
    days: null,
  },
  hasChanges: false,
  status: CarePlanStatus.Draft,
  alertsDefinition: [],
  importantMetricDefinitionIds: [],
  awsStateMachineArn: '',
  actionsJson: '',
  updateDate: null,
  publishDate: null,
  type: 'CROSS_CARE_TEAM',
} as CarePlan;

const initialState = {
  currentType: GqlCarePlanType.Workspace,
  list: {
    workspace: (containerInitialState as unknown) as CarePlansContainer,
    template: (containerInitialState as unknown) as CarePlansContainer,
  },
  builder: {
    currentCarePlan: emptyCarePlan,
    carePlanVariables: [],
    isNew: false,
    currentPart: BuilderParts.Settings,
    data: emptyCarePlan as CarePlan,
    actionsJson: '',
    request: {
      isLoading: true,
      loadedEmpty: false,
      loadedData: false,
      error: {},
    },
  },
  filters: {
    categories: [],
    organization: null,
    author: null,
    order: OrderByDate.Newest,
  },
} as CarePlansState;

const slice = createSlice({
  name: 'CarePlansState',
  initialState,
  reducers: {
    setDefaultValue() {
      return initialState;
    },
    changeType(state, action: PayloadAction<GqlCarePlanType>) {
      if (state.currentType === action.payload) return;
      state.currentType = action.payload;
    },
    setItems(state, action: PayloadAction<any>) {
      const container = getContainer(state, action.payload.type);
      const data = action.payload.data;
      container.items =
        [
          ...container.items,
          ...data.planDefinition.planDefinitionPagination.items,
        ] || [];
      container.pageInfo =
        data.planDefinition.planDefinitionPagination.pageInfo;
    },
    setBuilderEmpty(state) {
      state.builder.data = emptyCarePlan as CarePlan;
    },
    setBuilderIsNew(state, action: PayloadAction<boolean>) {
      if (state.builder.isNew === action.payload) return;
      state.builder.isNew = action.payload;
      // state.builder.data = emptyCarePlan as CarePlan;
    },
    changeBuilderPart(state, action: PayloadAction<BuilderParts>) {
      if (state.builder.currentPart === action.payload) return;
      state.builder.currentPart = action.payload;
    },
    setBuilder_Data(state, action: PayloadAction<CarePlan>) {
      state.builder.data = action.payload;
    },
    setBuilder_GetOneData(
      state,
      action: PayloadAction<{
        data: GetOne_ResponseData | undefined;
        called: boolean;
        loading: boolean;
        error: any;
      }>
    ) {
      state.builder.request.isLoading =
        !action.payload.data && action.payload.loading;
      state.builder.request.loadedEmpty =
        !action.payload.data && !action.payload.loading;
      state.builder.request.loadedData =
        !!action.payload.data && !action.payload.loading;
      state.builder.request.error = action.payload.error;
      if (action.payload.data) {
        state.builder.data = action.payload.data.carePlan.one;
      }
    },
    setBuilder_CreateData(state, action: PayloadAction<Create_ResponseData>) {
      if (!state.builder.data) {
        state.builder.data = emptyCarePlan as CarePlan;
      }
      state.builder.data.id = action.payload?.carePlan?.create?.resultId;
    },
    setBuilder_UpdateData(state) {},
    setBuilder_DeleteData(state, action: PayloadAction<Create_ResponseData>) {
      //state.builder.data = action.payload.carePlan.one
    },
    setJson(state, { payload }) {
      state.builder.actionsJson = payload.actionsJson;
    },
    setAlerts(state, { payload }) {
      state.builder.currentCarePlan = {
        ...state.builder.currentCarePlan,
        alertDefinitions: payload,
      };
      state.builder.currentCarePlan.hasChanges = true;
    },
    //TODO refactor types
    updateCarePlan(state, { payload }: { payload: CarePlan }) {
      const updatedList = state.list.workspace.items.filter(
        (item) => payload && item.id !== payload.id
      );
      state.list.workspace.items = [...updatedList, payload];
    },
    setCurrentCarePlan(state, { payload }: { payload: CarePlan }) {
      state.builder.currentCarePlan = payload;
    },
    updateCurrentCarePlan(state, { payload }: { payload: CarePlan }) {
      state.builder.currentCarePlan = {
        ...state.builder.currentCarePlan,
        ...payload,
      };
    },
    removeCurrentCarePlan(state) {
      state.builder.currentCarePlan = emptyCarePlan;
    },
    setCarePlanVariables(
      state,
      { payload }: { payload: { variables: Variable[] } }
    ) {
      state.builder.carePlanVariables = payload.variables;
    },
    updateFilters(state, { payload }: any) {
      state.filters = { ...state.filters, ...payload };
    },
    setImportantMetrics(state, { payload }) {
      state.builder.currentCarePlan.importantMetricDefinitionIds = payload;
      state.builder.currentCarePlan.hasChanges = true;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
