import { createSlice } from '@reduxjs/toolkit';

export const getModuleState = (state) => state.patients;

export const selectors = {
  getPatients(state) {
    const { patients, pageInfo } = getModuleState(state);
    return { patients, pageInfo };
  },
};

const slice = createSlice({
  name: 'patients',
  initialState: {
    patients: [],
    pageInfo: null,
  },
  reducers: {
    setPatientsWithPageInfo(state, { payload }) {
      state.patients = payload.patients;
      state.pageInfo = payload.pageInfo
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
