import { createSlice, current } from '@reduxjs/toolkit';
import { addEdge, applyNodeChanges } from 'react-flow-renderer';

export const getModuleState = (state) => state.widget;

export const prepareWidgetData = (widget) => {
  const {
    connectable,
    position,
    id,
    type,
    data,
    uid,
    vertex,
    height,
    width,
  } = widget;
  return {
    connectable,
    data,
    position,
    id,
    type,
    uid,
    vertex,
    height,
    width,
  };
};

export const selectors = {
  getNodes(state) {
    const { nodes } = getModuleState(state);
    return nodes;
  },
  getEdges(state) {
    const { edges } = getModuleState(state);
    return edges;
  },
  getCurrentWidget(state) {
    const { currentWidget } = getModuleState(state);
    return currentWidget;
  },
  getMaxId(state) {
    const { maxId } = getModuleState(state);
    return maxId;
  },
  getZoomState(state) {
    const { zoom } = getModuleState(state);
    return zoom;
  },
  getResizingState(state) {
    const { resizing } = getModuleState(state);
    return resizing;
  },
  getWidgetError(state) {
    const { widgetError } = getModuleState(state);
    return widgetError;
  },
};

const initialState = {
  nodes: [
    {
      connectable: true,
      data: {
        type: 'start',
      },
      position: {
        x: 200,
        y: 150,
      },
      id: '0',
      uid: 'StartWidget',
      type: 'special',
      height: 60,
      width: 120,
      vertex: true,
    },
  ],
  edges: [],
  maxId: 1,
  currentWidget: {},
  zoom: 1,
  resizing: false,
  widgetError: null,
};

const slice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    setDefaultValue: () => initialState,
    changeNodes(state, { payload }) {
      const nodes = current(state.nodes);
      const widget = prepareWidgetData(payload);
      const res = [
        ...nodes.filter((element) => element.id !== widget.id),
        widget,
      ];
      state.nodes = res;
    },
    changeEdges(state, { payload }) {
      const edges = current(state.edges);
      const res = [
        ...edges.filter((element) => element.id !== payload.id),
        payload,
      ];
      state.edges = res;
    },
    setConnect(state, { payload }) {
      const edges = current(state.edges);
      state.edges = addEdge(payload, edges);
    },
    addWidget(state, { payload }) {
      state.nodes = [...state.nodes, payload];
      state.currentWidget = payload;
      state.maxId = state.maxId + 1;
    },
    addEdge(state, { payload }) {
      const id = payload.id ? payload.id : state.maxId.toString();
      const newEdge = {
        id,
        vertex: false,
        source: payload.source,
        target: payload.target,
        type: 'special',
        sourceHandle: 'main',
      };
      state.nodes = [...state.nodes, newEdge];
      state.currentWidget = newEdge;
      state.maxId = payload.id ? state.maxId : state.maxId + 1;
    },
    saveWidget(state, { payload }) {
      state.nodes = payload;
    },
    updateWidget(state, { payload }) {
      state.nodes = applyNodeChanges(payload, current(state.nodes));
    },
    updateWidgetError(state, { payload }) {
      state.widgetError = payload?.error ? payload?.error : null;
    },
    removeNode(state, { payload }) {
      state.nodes = state.nodes.filter(
        (item) => item.id !== payload && item.parentNode !== payload
      );
      const sources = state.edges.filter((item) => item.source !== payload);
      const edges = sources.filter(
        (item) => item.target !== payload && item.parentNode !== payload
      );
      state.edges = edges;
    },
    removeEdge(state, { payload }) {
      state.edges = state.edges.filter(
        (item) =>
          item.id !== payload.id &&
          (item.parentId || item.value?.parentId) !== payload.id &&
          item.source !== payload.id &&
          item.target !== payload.id
      );
    },
    saveWidgets(state, { payload }) {
      if (payload.nodes && Array.isArray(payload.nodes)) {
        state.nodes = [...payload.nodes];
        const maxId = Math.max(
          payload.maxId,
          ...payload.nodes
            .filter(function (item) {
              return !isNaN(item.id);
            })
            .map((item) => item.id)
        );
        state.maxId = maxId + 1;
      }
      if (payload.edges && Array.isArray(payload.edges)) {
        state.edges = [...payload.edges];
      }
    },
    setZoom(state, { payload }) {
      state.zoom = payload.zoom;
    },
    setResizing(state, { payload }) {
      state.resizing = payload.resizing;
    },
    selectWidget(state, { payload }) {
      state.currentWidget = prepareWidgetData(payload);
    },
    setWidgetError(state, { payload }) {
      state.widgetError = payload;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
