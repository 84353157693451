import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { GET_ALERTS_PAGE } from '../gqlSchemes/alertsPage';

function useGetAlertsPage(page, perPage, queryFilter, skip = false) {
  const [total, setTotal] = React.useState(null);

  const { data, loading, refetch, networkStatus } = useQuery(GET_ALERTS_PAGE, {
    fetchPolicy: 'no-cache',
    skip,
    variables: {
      page,
      perPage,
      filter: queryFilter,
    },
    onCompleted: () => {
      if (total === null)
        setTotal(data?.alert?.alertsPage?.pageInfo?.totalItems);
    },
  });

  const [getLazyQueryAlerts] = useLazyQuery(GET_ALERTS_PAGE);

  return {
    alerts: data?.alert?.alertsPage?.items,
    loading,
    pageInfo: data?.alert?.alertsPage?.pageInfo,
    total,
    refetch,
    getLazyQueryAlerts,
  };
}

export default useGetAlertsPage;
