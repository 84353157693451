export const eventUserStatuses = {
  booked: 'BOOKED',
  proposed: 'PROPOSED',
  cancelled: 'CANCELLED',
};

export const participantStatus = {
  accepted: 'ACCEPTED',
  declined: 'DECLINED',
  needsAction: 'NEEDS_ACTION',
}
