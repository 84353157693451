import { createSlice } from '@reduxjs/toolkit';
import { filter, indexBy, prop, values } from 'ramda';
import { parseISO } from 'date-fns';

import { eventUserStatuses, participantStatus } from 'lib/enums/eventUserStatuses';

export const getModuleState = (state) => state.calendar;

export const selectors = {
  getUpcoming(state) {
    const events = values(getModuleState(state).events);
    return events.filter((event) => (
      parseISO(event.startDate + '.000Z') > new Date() &&
      event.status === eventUserStatuses.booked
    ));
  },
  getPending(state) {
    const events = values(getModuleState(state).events);
    return events.filter((event) => {
      const patientStatusesIsApproved =
        event?.patientParticipant?.status === participantStatus.needsAction
      return event.status === eventUserStatuses.proposed
        && patientStatusesIsApproved
        && parseISO(event.startDate + +'.000Z') > new Date()
    });
  },
  getRequested(state) {
    const events = values(getModuleState(state).events);
    return events.filter((event) => {
      const userStatusesIsApproved =
        event?.practitionerParticipants?.length &&
        event?.practitionerParticipants?.every((item) =>
          item?.status === participantStatus.needsAction
        ) 
        &&
        event?.status != eventUserStatuses.cancelled
        

      return (parseISO(event.startDate + '.000Z') > new Date() && userStatusesIsApproved)
    });
  },
  getCanceled(state) {
    const events = values(getModuleState(state).events);
    return events.filter((event) =>
      (event.status === eventUserStatuses.cancelled) ||
      (parseISO(event.startDate + +'.000Z') < new Date())
    );
  },
  getWorkingHours(state) {
    return getModuleState(state).workingHours;
  },
  getDefaultAppointmentDuration(state) {
    return getModuleState(state).defaultAppointmentDuration;
  },
  getCurrentView(state) {
    return getModuleState(state).currentView;
  },
  getAbsenceError(state) {
    return getModuleState(state).absenceError;
  },
};

const slice = createSlice({
  name: 'calendar',
  initialState: {
    absences: {},
    absenceError: '',
    events: {},
    eventError: '',
    workingHours: [],
    appointments: {},
    appointmentsError: '',
    appointmentDuration: 30,
    eventDuration: 30,
    currentView: 'week',
    selectedEvent: {
      date: null,
      eventId: null,
    },
  },
  reducers: {
    addEvent(state, { payload }) {
      const { event } = payload;
      state.events[event.id] = event;
    },
    highlightEvent(state, { payload }) {
      state.selectedEvent = {
        eventId: payload.eventId,
        date: payload.eventId && state.events[payload.eventId]
          ? state.events[payload.eventId].startDate
          : payload.date,
      };
    },
    updateEvent(state, { payload }) {
      const { data } = payload;
      state.events[data.id] = {
        ...state.events[data.id],
        ...data,
      };
    },
    setWorkingHours(state, { payload }) {
      state.workingHours = payload;
    },
    setDefaultAppointmentDuration(state, { payload }) {
      state.appointmentDuration = payload;
    },
    setEvents(state, { payload }) {
      const { events } = payload;
      state.events = indexBy(prop('id'), events);
    },
    setEventError(state, { payload }) {
      const { error } = payload;
      state.eventError = error;
    },
    setCurrentView(state, { payload }) {
      const { view } = payload;
      state.currentView = view;
    },
    cancelEvent(state, { payload }) {
      const { id, isEvent } = payload;
      if (isEvent && state.events[id]) {
        state.events[id] = {
          ...state.events[id],
          patients: [
            {
              ...state.events[id].patients[0],
              status: eventUserStatuses.cancelled,
            },
          ],
          users: [
            {
              ...state.events[id].users[0],
              status: eventUserStatuses.cancelled,
            },
          ],
        };
      }

      state.events[id] = {
        ...state.events[id],
        patient: {
          ...state.events[id].patient,
          status: eventUserStatuses.cancelled,
        },
        user: {
          ...state.events[id].user,
          status: eventUserStatuses.cancelled,
        },
      };
    },
    setAbsences(state, { payload }) {
      const { absences } = payload;
  
      state.absences = indexBy(prop('id'), absences);
    },
    setAbsencesError(state, { payload }) {
      const { error } = payload;
    
      state.absenceError = error;
    },
    setAppointments(state, { payload }) {
      state.events = {...state.events, payload};
    },
    changeStatusToAppointmentById(state, { payload }) {
      state.events = {
        ...state.events,
        [payload.id]: {
          ...state.events[payload.id],
          ...payload
        }
      };
    },
    setAppointmentsError(state, { payload }) {
      const { error } = payload;
      state.appointmentsError = error;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
