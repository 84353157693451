import {
  ApolloClient,
  InMemoryCache,
  concat,
  from,
  ApolloLink,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import config from 'config';
import omitDeep from 'omit-deep-lodash';

const removeTypenameFromMutation = (operation, forward) => {
  // this link checks if we're doing a mutation and removes __typename
  const definition = operation?.query?.definitions.filter(
    (def) => def.kind === 'OperationDefinition'
  )?.[0];
  if (
    definition?.kind === 'OperationDefinition' &&
    definition?.operation === 'mutation'
  ) {
    operation.variables = omitDeep(operation.variables, '__typename');
    return forward(operation);
  }
  return forward(operation);
};

export const apolloCache = new InMemoryCache({
  addTypename: true, // __typename is needed for proper caching
 });

const getClient = (authLink, errorLink) => {
  return new ApolloClient({
    connectToDevTools: true,
    link: from([
      concat(
        authLink,
        ApolloLink.from([
          errorLink,
          removeTypenameFromMutation,
          createUploadLink({
            uri:
              process.env.NODE_ENV === 'development'
                ? config.API_HOST_DEV + '/graphql'
                : config.API_HOST + '/graphql',
            fetch: (url, options) => {
              // console.log({ url }, { options }) uncomment this to see request info
              if (options.body instanceof FormData) {
                const filterFn = (obj, predicate) =>
                  Object.assign(
                    ...Object.keys(obj)
                      .filter((key) => predicate(key))
                      .map((key) => ({ [key]: obj[key] }))
                  );
                options.headers = filterFn(
                  options.headers,
                  (i) => i.toLowerCase() !== 'Content-Type'.toLowerCase()
                );
              }
              return fetch(url, options);
            },
          }),
        ])
      ),
    ]),
    cache: apolloCache,
  });
};

export default getClient;
