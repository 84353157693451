import { Action, configureStore, getDefaultMiddleware, Reducer } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import rootReducer, { RootState } from './reducer';

const store = configureStore({
  reducer: rootReducer as Reducer<RootState, Action>,
  middleware: getDefaultMiddleware({
    // serializableCheck: {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
    serializableCheck: false
  }),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
export default store;
