import colors from './index';

const palette = {
  primary: colors.blue100,
  secondary: colors.blue100,
  tertiary: colors.black100,
  error: '',
  warning: colors.red100,
  info: colors.brown,
  success: colors.green100,
  textPrimary: colors.black100,
  textSecondary: colors.gray400,
  textWhite: colors.white,
};

export default palette;
