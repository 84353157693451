import { createSlice } from '@reduxjs/toolkit';

export const getModuleState = (state) => state.chat;

export const selectors = {
  getUnreadMessageCount(state) {
    const { unreadMessageCount } = getModuleState(state);
    
    return unreadMessageCount;
  },
  getChatDraftText(state, chatId) {
    if (!chatId) {
      return '';
    }
    
    const { draftText } = getModuleState(state).chats[chatId] || {};
    return draftText;
  }
};

const slice = createSlice({
  name: 'chat',
  initialState: {
    chats: {},
    unreadMessageCount: 0,
  },
  reducers: {
    setUnreadMessageCount(state, { payload }) {
      state.unreadMessageCount = payload;
    },
    setDraftText(state, { payload }) {
      const { chatId, draftText } = payload;
      if (!state.chats[chatId]) {
        // Create the chat object if it doesn't exist
        state.chats[chatId] = {};
      }
      state.chats[chatId].draftText = draftText;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
