import { gql } from '@apollo/client';

export const GET_ALERTS_PAGE = gql`
  query GetAlerts($page: Int!, $perPage: Int!, $filter: GqlAlertsFilter!) {
    alert {
      alertsPage(page: $page, perPage: $perPage, filter: $filter) {
        pageInfo {
          totalItems
          page
          perPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
        items {
          id
          alertDefinitionId
          alertDefinition {
            id
            metricDefinitionIds
            metricDefinitions {
              id
              title
            }
            title
            triggerRules {
              rules {
                id
                __typename
                metricDefinitionId
                metricDefinition {
                  __typename
                  id
                  title
                }
                evaluationStrategyType
                evaluationStrategy {
                  __typename
                  ... on GqlRepetitionEvaluationStrategy {
                    dataType
                    sign
                    count
                    duration
                    unit
                    valueDecimal
                    valueString
                  }
                  ... on GqlElevationEvaluationStrategy {
                    dataType
                    direction
                    base
                    valueDecimal
                  }
                  ... on GqlDefaultEvaluationStrategy {
                    dataType
                    sign
                    valueDecimal
                    valueString
                  }
                }
              }
            }
            criticity
            resolutionTime {
              duration
              unit
              isImmediate
            }
            assignedToId
            assignedToType
            escalationRules {
              id
              assignedToId
              assignedToType
              duration
              unit
            }
            note
          }
          metricDefinitionIds
          metricDefinitions {
            id
            title
          }
          patientId
          patient {
            id
            name {
              firstName
              lastName
            }
            photoUrl
            birthDate
          }
          assignedToId
          assignedTo {
            id
            firstName
            lastName
            photoUrl
            birthDate
          }
          escalation {
            id
            status
            assignedToId
            assignedToType
            assignedTo {
              __typename
              ... on GqlPractitioner {
                id
                firstName
                lastName
                photoUrl
              }
              ... on GqlOnCallGroup {
                id
                name
                careTeam {
                  id
                  name
                }
                practitioners {
                  id
                  firstName
                  lastName
                }
              }
            }
            escalationTime
          }
          title
          triggers {
            triggers {
              id
              triggerRuleId
              triggerRule {
                metricDefinition {
                  id
                  title
                }
                evaluationStrategy {
                  __typename
                  evaluationStrategyType
                  ... on GqlRepetitionEvaluationStrategy {
                    dataType
                    sign
                    count
                    duration
                    unit
                    valueDecimal
                    valueString
                  }
                  ... on GqlElevationEvaluationStrategy {
                    dataType
                    direction
                    base
                    valueDecimal
                  }
                  ... on GqlDefaultEvaluationStrategy {
                    dataType
                    sign
                    valueDecimal
                    valueString
                  }
                }
              }
              valueString
              valueDecimal
              valueQuantity {
                value
                unit
                system
                code
              }
            }
          }
          status
          criticity
          note
          resolutionTime
          nextEscalationTime
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const UPDATE_ALERT = gql`
  mutation($record: GqlUpdateAlertInput!) {
    alert {
      updateAlert(record: $record) {
        ok
        error {
          message
          code
        }
        resultId
        result {
          id
          title
        }
      }
    }
  }
`;
