import React from 'react';
import { ThemeProvider as StyleThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import theme from 'lib/theme';

function ThemeProvider(props) {
  return (
    <StyleThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        {props.children}
      </MuiThemeProvider>
    </StyleThemeProvider>
  );
}

export default ThemeProvider;
