import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

export const getModuleState = (state) => state.user;

export const selectors = {
  getUser(state) {
    const { user } = getModuleState(state);
    return user;
  },
  getPermissionsList(state) {
    const { user } = getModuleState(state);
    const permissions = user?.newRoles[0]?.permissions;
    const list = {};
    for (let i = 0; i < permissions.length; i++) {
      list[permissions[i].id] = permissions[i].permissions;
    }
    return list;
  },
  getTriggeredAlertsCount(state) {
    const { user } = getModuleState(state);
    return user.triggeredAlerts;
  },
};

const slice = createSlice({
  name: 'user',
  initialState: {
    user: {},
  },
  reducers: {
    setUserInfo(state, { payload }) {
      const { user } = payload;
      state.user = { ...state.user, ...user };
    },
    setUserRoles(state, { payload }) {
      const { newRoles } = payload;
      state.user.newRoles = newRoles;
    },
    setWorkingHours(state, { payload }) {
      state.user.workingHours = payload.workingHours;
      state.user.appointmentDuration = payload.appointmentDuration;
    },
    setTriggeredAlerts(state, { payload }) {
      state.user.triggeredAlerts = payload;
    },
  },
});

export const actions = slice.actions;
export const reducer = persistReducer(
  {
    key: 'user',
    storage: storageSession,
  },
  slice.reducer
);
