import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './assets/i18n/en.json';
import fr from './assets/i18n/fr.json';
import arsa from './assets/i18n/ar-sa.json';
import br from './assets/i18n/br.json';
import de from './assets/i18n/de.json';
import id from './assets/i18n/id.json';
import nl from './assets/i18n/nl.json';
import es from './assets/i18n/sp.json';

i18n.use(initReactI18next).init({
  // allow keys to be phrases having `:`, `.`
  nsSeparator: false,
  keySeparator: false,

  resources: {
    en: { translation: en.master },
    fr: { translation: fr.master },
    sa: { translation: arsa.master },
    br: { translation: br.master },
    de: { translation: de.master },
    id: { translation: id.master },
    nl: { translation: nl.master },
    es: { translation: es.master },
  },
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
