const config = {
  API_HOST: process.env.REACT_APP_API_HOST,
  API_HOST_DEV: process.env.REACT_APP_API_HOST_DEV,
  SENDBIRD_APP_ID: process.env.REACT_APP_SENDBIRD_APP_ID,
  PRO_SANTE_CONNECT_HOST: process.env.REACT_APP_PRO_SANTE_CONNECT_HOST,
  PRO_SANTE_CONNECT_CLIENT_ID: process.env.REACT_APP_PRO_SANTE_CONNECT_CLIENT_ID,
  PRO_SANTE_CONNECT_AUTH_HOST:
    process.env.REACT_APP_PRO_SANTE_CONNECT_AUTH_HOST,
};

export default config;
