const colors = {
  black: '#000000',
  black100: '#222222',
  black200: '2C2C2C',
  white: '#ffffff',

  blue000: '#0077ff',
  blue100: '#2164E8',
  blue200: '#E9EFFC',
  blue300: '#134bbb',
  blue400: '#3B5998',
  blue500: '#40A9FF',
  blue600: '#4D83EC',

  lightblue100: '#F7F7F7',
  lightblue200: '#F3F5F9',
  bluePurple: '#A6C1F5',

  lightNavyBlue: '#4E667F',

  purple100: '#F6EFFD',
  purple200: '#D0ADF5',

  grayPurple: '#b6bdd7',
  grayPurple100: '#9797A7',

  pink: '#F7A5F7',

  gray: '#AAAAAA',
  gray100: '#f6f6f6',
  gray200: '#ececec',
  gray250: '#ebebeb',
  gray300: '#c8c8c8',
  gray350: '#909090',
  gray400: '#777777',
  gray500: '#3c4043',

  green: '#3CFD3E',
  green100: '#00AE4F',
  green200: '#008000',
  green300: '#cdf8c2',
  green400: '#1E7D39',
  green500: '#E5F7ED',
  green600: '#33cf4d',
  green700: '#65C466',

  jadeGreen000: '#82ca9d',
  jadeGreen100: '#27bf81',

  turquoise: '#2eba9f',

  red: '#FF0000',
  red000: '#F23A61',
  red100: '#DC1942',
  red200: '#800021',
  red300: '#FDD8E0',
  red400: '#FFCDD8',
  red500: '#A62639',

  yellow100: '#E1CE1E',
  yellow200: '#FFF9D6',
  yellow300: '#ffc658',
  yellow400: '#FEF6EE',
  yellow500: '#FAF4EA',

  orange100: '#F29D3A',

  brown: '#C04D29',

  bgYellow: '#FFFADE',
  bgRed100: '#FCEDEF',
  bgRed200: '#E89BA7',
  bgGreen: '#E5F7ED',
  bgOrange: '#FEF5EB',
  bgPurple: '#F6EFFD',
};

export default colors;
